import * as Toast from "@radix-ui/react-toast";
import type { LinksFunction, MetaFunction } from "@remix-run/node";
import { json } from "@remix-run/node";
import { Outlet, useCatch, useLoaderData, useLocation, useMatches } from "@remix-run/react";
import { useEffect } from "react";
import { getAnalytics, logEvent } from "firebase/analytics";
import clsx from "clsx";
import { ExclamationTriangleIcon } from "@radix-ui/react-icons";
import { Document } from "./components/Document";
import { Loading } from "./components/Loader";
import { WEBSITE_ENV } from "./lib/env.server";
import AppCss from "./styles/app.css";
import YakuhanJPCss from "./static/css/yakuhanjp.min.css";
import "dayjs/locale/ja";
import { ApplicationLayout } from "./components/ApplicationLayout";
import { initFirebase } from "./lib/firebase.client";
import { PageContainer } from "./components/PageContainer";
import { ArticleLayout } from "./components/ArticleLayout";
import { DisplayText } from "./components/DisplayText";

export const meta: MetaFunction = () => {
  return {
    charSet: "utf-8",
    viewport: `width=device-width, initial-scale=1, viewport-fit=cover`,
  };
};

export const links: LinksFunction = () => [
  { rel: "preconnect", href: "https://fonts.googleapis.com" },
  { rel: "preconnect", href: "https://fonts.gstatic.com" },
  {
    rel: "stylesheet",
    href: "https://fonts.googleapis.com/css2?family=Inter:wght@400;700;900&family=Noto+Sans+JP:wght@400;700;900&display=swap",
  },
  { rel: "stylesheet", href: AppCss },
  { rel: "stylesheet", href: YakuhanJPCss },
];

export function loader() {
  return json({ WEBSITE_ENV });
}

export default function App() {
  const { WEBSITE_ENV } = useLoaderData<typeof loader>();
  const matches = useMatches();
  const location = useLocation();
  const isHome = Boolean(matches.find((match) => match.handle?.isHome === true));
  const noIndex = Boolean(matches.find((match) => match.handle?.noindex === true));
  const canonical = matches.find((match) => match.data?.canonical)?.data.canonical;

  useEffect(() => {
    if (typeof window !== "undefined") {
      initFirebase();
    }
  }, []);

  useEffect(() => {
    if (typeof window === "undefined") return;
    const analytics = getAnalytics();
    const log = (url: string) => {
      logEvent(analytics, "screen_view", {
        firebase_screen: document.title,
        firebase_screen_class: url,
      });
      logEvent(analytics, "page_view", {
        page_location: url,
        page_title: document.title,
      });
    };
    log(location.pathname);
  }, [location]);

  return (
    <Document websiteEnv={WEBSITE_ENV} isHome={isHome} noindex={noIndex} canonical={canonical}>
      <Toast.Provider swipeDirection="right">
        <ApplicationLayout>
          <Outlet />
        </ApplicationLayout>
        <Loading.Page />
        <Toast.Viewport />
      </Toast.Provider>
    </Document>
  );
}

export function CatchBoundary() {
  const caught = useCatch();
  const title = caught.status === 404 ? "ページが見つかりませんでした。" : "エラーが発生しました。";
  const displayTitle = caught.status === 404 ? "404.Not Found" : "500.Server Errorr";
  const displayText =
    caught.status === 404
      ? "リクエストされたURLは見つかりませんでした。"
      : "予期しないエラーが発生しました。ウェブサイト管理者へお問い合わせ下さい。";
  return (
    <Document websiteEnv="production" noindex={true} title={title}>
      <ApplicationLayout>
        <PageContainer>
          <ArticleLayout paths={[]}>
            <div className={clsx("flex", "flex-col", "space-y-8")}>
              <DisplayText element="h1" size="extraLarge">
                {displayTitle}
              </DisplayText>
              <p className={clsx("px-4", "font-bold", "text-gray-600")}>
                <ExclamationTriangleIcon width={20} height={20} className="inline-block mr-2" />
                {displayText}
              </p>
            </div>
          </ArticleLayout>
        </PageContainer>
      </ApplicationLayout>
      <Loading.Page />
    </Document>
  );
}
